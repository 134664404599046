export default [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('views/home/HomePage'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
      topIndex: 0,
    }
  },
  {
    path: '/search',
    name: 'AdvancedSearch',
    component: () => import('views/home/AdvancedSearch'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
    }
  },
  {
    path: '/BookList',
    name: 'BookList',
    component: () => import('views/list/BookList'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
      topIndex: 1,
    }
  },
  {
    path: '/DataList',
    name: 'DataList',
    component: () => import('views/list/DataList'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
      topIndex: 2,
    }
  },
  {
    path: '/BookDetail',
    name: 'BookDetail',
    component: () => import('views/detail/BookDetail'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
      topIndex: 1,
    }
  },
  {
    path: '/DataDetail',
    name: 'DataDetail',
    component: () => import('views/list/DataDetail'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
      topIndex: 2,
    }
  },
  {
    path: '/PublisherDetail',
    name: 'PublisherDetail',
    component: () => import('views/home/PublisherDetail'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('views/home/AboutUs'),
    meta: {
      title: '知源知识服务平台 - 资源中心',
    }
  },
]